// extracted by mini-css-extract-plugin
export var captionDiv = "terms-conditions-module--captionDiv--22fac";
export var captionDiv22 = "terms-conditions-module--captionDiv22--25165";
export var displayB = "terms-conditions-module--displayB--f6cfa";
export var displayDiv = "terms-conditions-module--displayDiv--39538";
export var displayDiv2 = "terms-conditions-module--displayDiv2--4dd93";
export var docTemplateDiv = "terms-conditions-module--docTemplateDiv--a783e";
export var gToUpload = "terms-conditions-module--gToUpload--c0824";
export var inAdditionTo = "terms-conditions-module--inAdditionTo--14136";
export var pDiv = "terms-conditions-module--pDiv--816aa";
export var pDiv1 = "terms-conditions-module--pDiv1--3d3a0";
export var privacyPolicyB = "terms-conditions-module--privacyPolicyB--0b777";
export var returnsPolicySpan = "terms-conditions-module--returnsPolicySpan--ceb55";
export var subtitleDiv = "terms-conditions-module--subtitleDiv--c1590";
export var subtitleLargeDiv = "terms-conditions-module--subtitleLargeDiv--1f816";
export var termsConditions = "terms-conditions-module--termsConditions--96519";
export var textColumnDiv = "terms-conditions-module--textColumnDiv--cca08";
export var textbodyDiv = "terms-conditions-module--textbodyDiv--ec122";
export var textbodyDiv4 = "terms-conditions-module--textbodyDiv4--cf47e";
export var titleDiv = "terms-conditions-module--titleDiv--16ee8";